<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="editSchoolValidation">
            <b-form @submit.prevent>
                <b-card>
                    <template v-if='school'>
                        <b-row>
                            <b-col><h3>{{ $t("general.edit_school") }}</h3></b-col>
                            <b-col class="text-right">
                                <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols='12' sm='6'>
                                <validation-provider
                                    :name="$t('general.school_type')"
                                    #default="{ errors }"
                                    rules="required"
                                >
                                    <label for='school_type'>{{ $t('general.school_type') }}</label>
                                    <v-select :clearable='false' label="Name" :options='schoolTypes' id='school_type' v-model="selectedSchoolType"></v-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                            <b-col cols='12' sm='6'>
                                <b-form-checkbox class="mt-2" id='active' v-model="school.Active">{{ $t('general.active') }}</b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols='12' sm='6'>
                                <validation-provider
                                    :name="$t('general.school_title')"
                                    #default="{ errors }"
                                    rules="required"
                                >
                                    <label for='school_title'>{{ $t('general.school_title') }}</label>
                                    <b-form-input id='school_title' v-model="school.Name"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                            <b-col cols='12' sm='6'>
                                <validation-provider
                                    :name="$t('general.school_short')"
                                    #default="{ errors }"
                                    rules="required"
                                >
                                    <label for='school_short'>{{ $t('general.school_short') }}</label>
                                    <b-form-input id='school_short' v-model="school.ShortName"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols='12' sm='6'>
                                <validation-provider
                                    :name="$t('general.school_user')"
                                    #default="{ errors }"
                                    rules="required"
                                >
                                    <label for='school_user'>{{ $t('general.school_user') }}</label>
                                    <b-form-input id='school_user' v-model="school.User.Username"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1">
                            <b-col class="text-right">
                                <b-button @click="validationForm" variant="primary">{{ $t('general.save') }}</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-card>
            </b-form>
        </validation-observer>

        <validation-observer ref="editUserValidation">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col><h3>{{ $t("general.update_password") }}</h3></b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' sm='6' lg="6"
                               class="mb-1"
                        >
                            <validation-provider
                                :name="$t('general.password')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <!-- button on right -->
                                <label for='password'>{{ $t('general.password') }}</label>
                                <b-input-group id='password'> 
                                    <b-form-input  v-model="password" />
                                    <b-input-group-append>
                                        <b-button variant="outline-primary" @click="generatePassword">
                                            {{ $t('general.generate') }}
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right">
                            <b-button @click="onValidateUserAndPassword" variant='primary'>{{ $t('general.save_password') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BFormInput, BForm, BFormCheckbox, BInputGroup, BInputGroupAppend, BButton} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required } from '@validations'
    export default {
        components: {BCol, BRow, BOverlay, BCard, BFormInput, BForm, BFormCheckbox, BInputGroup, BInputGroupAppend, BButton, vSelect, ValidationProvider, ValidationObserver},
        data() {
            return {
                id: 0,
                school_title: '',

                showLoader: false,
                schoolTypes: [],
                selectedSchoolType: null,
                school_user: '',
                password: '',
                school: null,
                required
            }
        },
        methods: {
            onValidateUserAndPassword() {
                this.$refs.editUserValidation.validate().then((response) => {
                    if (response) {
                        this.onEditUser()
                    }
                })
            },
            onEditUser() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    ID: thisIns.school.UserID,
                    Password: thisIns.password
                }
                thisIns.$http.patch(`/v1/admin/sola/${thisIns.id}/sola_geslo`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.password_successfully_updated')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.go(-1)
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            validationForm() {
                this.$refs.editSchoolValidation.validate().then((response) => {
                    if (response) {
                        this.onEditSchool()
                    }
                })
            },
            generatePassword() {
                let generatedPassword           = ''
                const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!"#$%&/()=?*'
                const charactersLength = characters.length
                const passwordLength = 15
                for (let i = 0; i < passwordLength; i++) {
                    generatedPassword += characters.charAt(Math.floor(Math.random() * charactersLength))
                }
                this.password = generatedPassword
            },
            onEditSchool() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Name: thisIns.school.Name,
                    ShortName: thisIns.school.ShortName,
                    UserID: thisIns.school.UserID,
                    TypeID: thisIns.selectedSchoolType.ID,
                    User: thisIns.school.User,
                    Active: thisIns.school.Active
                }
                thisIns.$http.patch(`/v1/admin/sola/${thisIns.id}`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.school_successfully_edited')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.go(-1)
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.edit_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            loadData() {
                const thisIns = this
                thisIns.showLoader = true

                thisIns.$http.get(`/v1/admin/sola/${this.id}`)
                    .then(res => {
                        this.school = res.data
                        this.selectedSchoolType = this.schoolTypes.find(s => s.Name === res.data.SchoolType.Name)
                        //this.active = !!this.school.User.IsActive
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getSchoolTypes() {
                const thisIns = this
                thisIns.showLoader = true

                thisIns.$http.get('/v1/admin/vrstaSole')
                    .then(res => {
                        thisIns.schoolTypes = res.data
                        thisIns.showLoader = false
                        thisIns.loadData()
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },

        created()  {
            this.id = this.$route.params.school_id
        },
        mounted() {
            this.getSchoolTypes()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>